import { i18n } from '@/i18n';
import { SettingsStorage } from '@/modules/settings/settings-storage';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import FilesField from '@/shared/fields/files-field';
import IntegerField from '@/shared/fields/integer-field';
import Permissions from '@/security/permissions';
import { GenericModel } from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';
import EnumeratorField from '@/shared/fields/enumerator-field';
import { ResearchFocusField } from '@/modules/research-focus/research-focus-field';
import { SubjectAreaField } from '@/modules/subject-area/subject-area-field';
import { FacultyField } from '@/modules/faculty/faculty-field';
import { CooperationPartnerField } from '@/modules/cooperation-partner/cooperation-partner-field';
import { FundingAgencyField } from '@/modules/funding-agency/funding-agency-field';
import { UserField } from '@/modules/auth/user-field';
import { ProjectTeamField } from '@/modules/project-team/project-team-field';
import Storage from '@/security/storage';
import DecimalField from '@/shared/fields/decimal-field';

function label(name) {
  return i18n(`entities.projectCreate.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.projectCreate.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  visibleExternallyEnum: new EnumeratorField(
    'visibleExternallyEnum',
    label('visibleExternallyEnum'),
    [
      {
        id: 'yes',
        label: enumeratorLabel('visibleExternallyEnum', 'yes'),
      },
      {
        id: 'no',
        label: enumeratorLabel('visibleExternallyEnum', 'no'),
      },
    ],
    {
      required: true,
    },
  ),
  title: new StringField('title', label('title'), {
    max: 255,
    required: true,
  }),
  description: new StringField('description', label('description'), {
    required: true,
    max: 5000,
  }),
  researchFocus: ResearchFocusField.relationToMany(
    'researchFocus',
    label('researchFocus'),
    {
      required: true,
    },
  ),
  subjectArea: SubjectAreaField.relationToMany(
    'subjectArea',
    label('subjectArea'),
    {
      required: true,
    },
  ),
  faculty: FacultyField.relationToMany('faculty', label('faculty'), {
    required: true,
  }),
  projectTeam: ProjectTeamField.relationToOne(
    'projectTeam',
    label('projectTeam'),
    {},
  ),
  projectManager: UserField.relationToMany(
    'projectManager',
    label('projectManager'),
    {
      required: true,
    },
  ),
  teamMember: UserField.relationToMany('teamMember', label('teamMember'), {}),
  cooperationPartnerEnum: new EnumeratorField(
    'cooperationPartnerEnum',
    label('cooperationPartnerEnum'),
    [
      {
        id: 'yes',
        label: enumeratorLabel('cooperationPartnerEnum', 'yes'),
      },
      {
        id: 'no',
        label: enumeratorLabel('cooperationPartnerEnum', 'no'),
      },
    ],
    {
      required: true,
    },
  ),
  cooperationPartner: CooperationPartnerField.relationToMany(
    'cooperationPartner',
    label('cooperationPartner'),
    {
      required: true,
    },
  ),
  plannedAmount: new DecimalField('plannedAmount', label('plannedAmount'), {
    scale: 2,
    min: 0,
    max: 10000000,
    required: true,
  }),
  timeSheet: new FilesField(
    'timeSheet',
    label('timeSheet'),
    Storage.values.projectCreateTimeSheet,
    Permissions.getFilePermissions('projectCreateFileFields', 'timeSheet'),
    {
      required: false,
      max: SettingsStorage.getByName('MAX_UPLOAD_FILE_SIZE'),
      formats: ['pdf', 'doc', 'docx'],
    },
  ),
  costsPlan: new FilesField(
    'costsPlan',
    label('costsPlan'),
    Storage.values.projectCreateCostsPlan,
    Permissions.getFilePermissions('projectCreateFileFields', 'costsPlan'),
    {
      required: true,
      max: SettingsStorage.getByName('MAX_UPLOAD_FILE_SIZE'),
      formats: ['pdf', 'doc', 'docx', 'xls', 'xlsx'],
    },
  ),
  descriptionThirdPartyFunding: new FilesField(
    'descriptionThirdPartyFunding',
    label('descriptionThirdPartyFunding'),
    Storage.values.projectCreateDescriptionThirdPartyFunding,
    Permissions.getFilePermissions(
      'projectCreateFileFields',
      'descriptionThirdPartyFunding',
    ),
    {
      required: true,
      max: SettingsStorage.getByName('MAX_UPLOAD_FILE_SIZE'),
      formats: ['pdf', 'doc', 'docx'],
    },
  ),
  financialGuideline: new FilesField(
    'financialGuideline',
    label('financialGuideline'),
    Storage.values.projectCreateFinancialGuideline,
    Permissions.getFilePermissions(
      'projectCreateFileFields',
      'financialGuideline',
    ),
    {
      required: false,
      max: SettingsStorage.getByName('MAX_UPLOAD_FILE_SIZE'),
      formats: ['pdf', 'doc', 'docx'],
    },
  ),
  fundingAgencyEnum: new EnumeratorField(
    'fundingAgencyEnum',
    label('fundingAgencyEnum'),
    [
      {
        id: 'yes',
        label: enumeratorLabel('fundingAgencyEnum', 'yes'),
      },
      { id: 'no', label: enumeratorLabel('fundingAgencyEnum', 'no') },
    ],
    {
      required: true,
    },
  ),
  fundingAgency: FundingAgencyField.relationToMany(
    'fundingAgency',
    label('fundingAgency'),
    {
      required: true,
    },
  ),
  projectDuration: new IntegerField(
    'projectDuration',
    label('projectDuration'),
    {
      required: true,
      max: 120,
      min: 1,
    },
  ),
  status: new EnumeratorField(
    'status',
    label('status'),
    [
      { id: 'start', label: enumeratorLabel('status', 'start') },
      { id: 'title', label: enumeratorLabel('status', 'title') },
      {
        id: 'description',
        label: enumeratorLabel('status', 'description'),
      },
      {
        id: 'researchFocus',
        label: enumeratorLabel('status', 'researchFocus'),
      },
      {
        id: 'subjectArea',
        label: enumeratorLabel('status', 'subjectArea'),
      },
      { id: 'faculty', label: enumeratorLabel('status', 'faculty') },
      {
        id: 'projectTeam',
        label: enumeratorLabel('status', 'projectTeam'),
      },
      {
        id: 'cooperationPartner',
        label: enumeratorLabel('status', 'cooperationPartner'),
      },
      {
        id: 'projectTeam',
        label: enumeratorLabel('status', 'projectTeam'),
      },
      {
        id: 'costsPlan',
        label: enumeratorLabel('status', 'costsPlan'),
      },
      {
        id: 'fundingAgency',
        label: enumeratorLabel('status', 'fundingAgency'),
      },
      {
        id: 'completed',
        label: enumeratorLabel('status', 'completed'),
      },
      {
        id: 'duration',
        label: enumeratorLabel('status', 'duration'),
      },
    ],
    { required: true },
  ),

  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),
};

export class ProjectCreateModel extends GenericModel {
  static get fields() {
    return fields;
  }
}

<template>
  <div>
    <div
      class="app-page-spinner"
      v-if="loading"
      v-loading="loading"
    ></div>
    <br />
    <el-form
      :label-position="labelPosition"
      :label-width="labelWidthForm"
      @submit.prevent.native
      class="form"
      v-if="record"
    >
      <app-view-item-text
        :label="fields.title.label"
        :value="presenter(record, 'title')"
      ></app-view-item-text>

      <app-view-item-text
        :label="fields.description.label"
        :value="presenter(record, 'description')"
      ></app-view-item-text>

      <app-view-item-relation-to-many
        :label="fields.researchFocus.label"
        :readPermission="fields.researchFocus.readPermission"
        :navigatePermission="fields.researchFocus.navigatePermission"
        :url="fields.researchFocus.viewUrl"
        :value="presenter(record, 'researchFocus')"
      ></app-view-item-relation-to-many>

      <app-view-item-relation-to-many
        :label="fields.subjectArea.label"
        :readPermission="fields.subjectArea.readPermission"
        :navigatePermission="fields.subjectArea.navigatePermission"
        :url="fields.subjectArea.viewUrl"
        :value="presenter(record, 'subjectArea')"
      ></app-view-item-relation-to-many>

      <app-view-item-relation-to-many
        :label="fields.faculty.label"
        :readPermission="fields.faculty.readPermission"
        :navigatePermission="fields.faculty.navigatePermission"
        :url="fields.faculty.viewUrl"
        :value="presenter(record, 'faculty')"
      ></app-view-item-relation-to-many>

      <app-view-item-relation-to-many
        :label="fields.projectManager.label"
        :readPermission="fields.projectManager.readPermission"
        :navigatePermission="fields.projectManager.navigatePermission"
        :url="fields.projectManager.viewUrl"
        :value="presenter(record, 'projectManager')"
      ></app-view-item-relation-to-many>

      <app-view-item-relation-to-many
        :label="fields.teamMember.label"
        :readPermission="fields.teamMember.readPermission"
        :navigatePermission="fields.teamMember.navigatePermission"
        :url="fields.teamMember.viewUrl"
        :value="presenter(record, 'teamMember')"
      ></app-view-item-relation-to-many>

      <app-view-item-text
        :label="fields.cooperationPartnerEnum.label"
        :value="presenter(record, 'cooperationPartnerEnum')"
      ></app-view-item-text>

      <app-view-item-relation-to-many
        :label="fields.cooperationPartner.label"
        :readPermission="fields.cooperationPartner.readPermission"
        :navigatePermission="
          fields.cooperationPartner.navigatePermission
        "
        :url="fields.cooperationPartner.viewUrl"
        :value="presenter(record, 'cooperationPartner')"
      ></app-view-item-relation-to-many>

      <app-view-item-text
        :label="fields.plannedAmount.label"
        :value="presenter(record, 'plannedAmount')"
      ></app-view-item-text>

      <app-view-item-file
        :label="fields.timeSheet.label"
        :value="presenter(record, 'timeSheet')"
        :permissions="
          fields.timeSheet.permissions
        "
      ></app-view-item-file>

      <app-view-item-file
        :label="fields.costsPlan.label"
        :value="presenter(record, 'costsPlan')"
        :permissions="fields.costsPlan.permissions"
      ></app-view-item-file>

      <app-view-item-file
        :label="fields.descriptionThirdPartyFunding.label"
        :value="presenter(record, 'descriptionThirdPartyFunding')"
        :permissions="
          fields.descriptionThirdPartyFunding.permissions
        "
      ></app-view-item-file>

       <app-view-item-file
        :label="fields.financialGuideline.label"
        :value="presenter(record, 'financialGuideline')"
        :permissions="
          fields.financialGuideline.permissions
        "
      ></app-view-item-file>

      <app-view-item-text
        :label="fields.fundingAgencyEnum.label"
        :value="presenter(record, 'fundingAgencyEnum')"
      ></app-view-item-text>

      <app-view-item-relation-to-many
        :label="fields.fundingAgency.label"
        :readPermission="fields.fundingAgency.readPermission"
        :navigatePermission="fields.fundingAgency.navigatePermission"
        :url="fields.fundingAgency.viewUrl"
        :value="presenter(record, 'fundingAgency')"
      ></app-view-item-relation-to-many>

      <app-view-item-text
        :label="fields.projectDuration.label"
        :value="presenter(record, 'projectDuration')"
      ></app-view-item-text>
    </el-form>
    <template slot="footer" slot-scope="props">
      <div class="wizard-footer-left">
        <wizard-button
          @click.native="props.prevTab()"
          :style="props.fillButtonStyle"
          ><app-i18n code="common.previous"></app-i18n
        ></wizard-button>
      </div>
      <div class="wizard-footer-right">
        <div class="wizard-btn">
          <wizard-button
            :disabled="saveLoading"
            @click.native="doSubmitFinish()"
            class="wizard-footer-right finish-button"
            :style="props.fillButtonStyle"
            ><app-i18n code="common.finish"></app-i18n
          ></wizard-button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ProjectCreateModel } from '@/modules/project-create/project-create-model';

const { fields } = ProjectCreateModel;

export default {
  name: 'app-project-create-view-page',

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      record: 'projectCreate/view/record',
      loading: 'projectCreate/view/loading',
      projectCreateId: 'project/form/projectCreateId',
    }),

    fields() {
      return fields;
    },
  },

  async created() {
    await this.doFind(this.projectCreateId);
  },

  methods: {
    ...mapActions({
      doFind: 'projectCreate/view/doFind',
    }),

    presenter(record, fieldName) {
      return ProjectCreateModel.presenter(record, fieldName);
    },
  },
};
</script>
